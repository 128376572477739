// Generated by Framer (508aa67)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./tzgFFTUbC-0.js";

const cycleOrder = ["Hi1vEtS1B", "xfkf2TWRS"];

const serializationHash = "framer-pphyo"

const variantClassNames = {Hi1vEtS1B: "framer-v-1jwh39n", xfkf2TWRS: "framer-v-1st1wos"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {LMCymGpQ1: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Closed: "Hi1vEtS1B", Open: "xfkf2TWRS"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, caVTnpE_A: tap ?? props.caVTnpE_A, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Hi1vEtS1B"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, caVTnpE_A, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Hi1vEtS1B", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1e0g1he = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (caVTnpE_A) {
const res = await caVTnpE_A(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1jwh39n", className, classNames)} data-framer-name={"Closed"} data-highlight layoutDependency={layoutDependency} layoutId={"Hi1vEtS1B"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap1e0g1he} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({xfkf2TWRS: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><motion.div className={"framer-4rogio"} data-framer-name={"Frame"} layoutDependency={layoutDependency} layoutId={"IunSFLsWW"} style={{rotate: 0}} variants={{xfkf2TWRS: {rotate: -180}}}><SVG className={"framer-orv5j3"} data-framer-name={"Icon"} layout={"position"} layoutDependency={layoutDependency} layoutId={"Vd9pEtxHP"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 14 14\"><path d=\"M 3 5.5 L 7 9.5 L 11 5.5\" fill=\"transparent\" stroke-width=\"2\" stroke=\"var(--token-c5f1da1f-e558-4012-bcfa-0c426f51b0a3, rgb(43, 159, 83)) /* {&quot;name&quot;:&quot;Main color&quot;} */\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg>"} svgContentId={1347124162} withExternalLayout/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-pphyo.framer-p1rpwi, .framer-pphyo .framer-p1rpwi { display: block; }", ".framer-pphyo.framer-1jwh39n { cursor: pointer; height: 14px; overflow: visible; position: relative; width: 14px; }", ".framer-pphyo .framer-4rogio { flex: none; height: 14px; left: calc(50.00000000000002% - 14px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 14px; }", ".framer-pphyo .framer-orv5j3 { flex: none; height: 14px; left: calc(50.00000000000002% - 14px / 2); position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 14px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 14
 * @framerIntrinsicWidth 14
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"xfkf2TWRS":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"caVTnpE_A":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramertzgFFTUbC: React.ComponentType<Props> = withCSS(Component, css, "framer-pphyo") as typeof Component;
export default FramertzgFFTUbC;

FramertzgFFTUbC.displayName = "Caret";

FramertzgFFTUbC.defaultProps = {height: 14, width: 14};

addPropertyControls(FramertzgFFTUbC, {variant: {options: ["Hi1vEtS1B", "xfkf2TWRS"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}, caVTnpE_A: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramertzgFFTUbC, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})